import { Link } from 'react-router-dom'

import { DeleteOutlined, HistoryOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Image, Button, Divider, Modal } from 'antd'

import { BgStatusTags, Product, canDeleteProduct, formatProductType, hasHeroImage } from '../..'
import * as S from './styles'

import anymarketLogo from '@/assets/logo-anymarket.svg'
import cisp1Logo from '@/assets/logo-cisp1.svg'
import vtexLogo from '@/assets/logo-vtex-single.svg'
import { formatCurrency, formatDate, imgFallback, RolePermissions, truncateString } from '@/common'
import { TableAction } from '@/components'
import { SelectProductActions } from '@/pages'

type BgCardProps = {
  data: Product
  isLoading?: boolean
  productPermissions: RolePermissions
  handleSelectProduct: ({ product, type }: SelectProductActions) => void
}

export const BgCard = ({ data, handleSelectProduct, productPermissions }: BgCardProps) => {
  const navigateRoute = data?.sku?.is_kit
    ? `/product/kit/${data.id}`
    : data?.status === 'EM RASCUNHO DO PRODUTO'
    ? `/create-product/${data.id}`
    : `/product/${data.id}`

  const dropdownOptions = [
    {
      key: '1',
      label: (
        <S.ButtonContent>
          <Button
            icon={<HistoryOutlined />}
            type="link"
            onClick={(e) => {
              e.preventDefault()
              // e.stopPropagation()

              handleSelectProduct({ product: data, type: 'VIEW_HISTORY' })
            }}
          >
            Ver histórico
          </Button>
        </S.ButtonContent>
      ),
    },
    (productPermissions?.isAdmin || productPermissions?.canDelete) && canDeleteProduct(data.status)
      ? {
          key: '2',
          label: (
            <S.ButtonContent>
              <Button
                danger
                type="link"
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.preventDefault()
                  // e.stopPropagation()

                  confirmDeletion(data)
                }}
                disabled={!canDeleteProduct(data.status)}
              >
                Excluir
              </Button>
            </S.ButtonContent>
          ),
        }
      : null,
  ]

  function confirmDeletion(product: Product) {
    Modal.confirm({
      title: `Deseja realmente excluir este produto?`,
      content: (
        <span>
          O produto <strong>{truncateString(product.name || product.model, 90)}</strong> será
          excluido permanentemente.
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
      onOk() {
        handleSelectProduct({ product, type: 'DELETE_PRODUCT' })
      },
    })
  }

  return (
    <Link to={navigateRoute}>
      <S.Container>
        <S.Row>
          <div style={{ display: 'flex', gap: 4 }}>
            <S.ImageContainer>
              <Image
                height={64}
                width={68}
                preview={false}
                fallback={imgFallback}
                alt={`Hero image de ${data.name}`}
                src={
                  hasHeroImage(data?.status)
                    ? data?.partner_files?.hero_image_partner_url
                    : data?.sku?.sku_files?.[0]?.filename_url
                }
              />
            </S.ImageContainer>

            <S.Column>
              <S.ProductFamilyName>
                <span>{formatProductType(data.product_type_name) || '--'}</span>

                <Divider type="vertical" style={{ margin: 0, padding: 0 }} />
              </S.ProductFamilyName>

              <S.ProductCategory>
                {truncateString(data?.category_name, 24) || '--'}
              </S.ProductCategory>
            </S.Column>
            <S.FamilyField>{truncateString(data?.family, 24) || '--'}</S.FamilyField>
          </div>

          <S.MktPricesRow>
            <S.PricesContainer $hasAdditionalMktPrices={Object.keys(data?.sku?.prices).length > 3}>
              <S.ProductPrice $hasMarketplacePrice={!!data?.sku?.prices.vtex || false}>
                <Image src={vtexLogo} width={24} height={24} alt="Logo VTEX" preview={false} />

                {truncateString(
                  formatCurrency({ value: data?.sku?.prices.vtex, type: 'currency' }),
                  15,
                )}
              </S.ProductPrice>

              <S.ProductPrice $hasMarketplacePrice={!!data?.sku?.prices.anymarket || false}>
                <Image
                  src={anymarketLogo}
                  width={24}
                  height={24}
                  alt="Logo AnyMarket"
                  preview={false}
                />

                {truncateString(
                  formatCurrency({
                    value: data?.sku?.prices.anymarket as number | string,
                    type: 'currency',
                  }),
                  14,
                )}
              </S.ProductPrice>

              {data?.sku?.prices.cisp1 && (
                <S.ProductPrice $hasMarketplacePrice={!!data?.sku?.prices.cisp1 || false}>
                  <Image src={cisp1Logo} width={24} height={24} alt="Logo Cisp1" preview={false} />

                  {truncateString(
                    formatCurrency({ value: data?.sku?.prices.cisp1, type: 'currency' }),
                    15,
                  )}
                </S.ProductPrice>
              )}
            </S.PricesContainer>
            {Object.keys(data?.sku?.prices).length > 3 && <span>Ver mais</span>}
          </S.MktPricesRow>
        </S.Row>

        <S.BGName>
          <S.BgModel>{data.model}</S.BgModel>{' '}
          {data.name ? truncateString(data.name, 70) : data.model}
        </S.BGName>
        <S.BadgesRowContainer>
          {data.is_b2b && <S.ProductCategoryType $isB2B>B2B Rental</S.ProductCategoryType>}
          {data.vtex_legacy && (
            <S.ProductCategoryType $isVtex>Importado Vtex</S.ProductCategoryType>
          )}
          {data.sku?.is_kit && <S.ProductCategoryType $isKit>Kit</S.ProductCategoryType>}
          {data.sku?.prices.cisp1 && <S.ProductCategoryType $isCsp1>CSP1</S.ProductCategoryType>}
        </S.BadgesRowContainer>

        {!data?.sku?.is_kit && (
          <TableAction
            isCard
            trigger={['hover']}
            icon={<S.DropdownIcon />}
            dropdownOptions={dropdownOptions}
          />
        )}

        <Divider style={{ margin: 0 }} />

        <S.Row>
          <BgStatusTags status={data.status} />
          <S.DateContainer>
            <span>Atualizado em</span>
            <S.Date>{formatDate({ date: data.updated_at, dateFormat: 'DD/MM/YYYY' })}</S.Date>
          </S.DateContainer>
        </S.Row>
      </S.Container>
    </Link>
  )
}
